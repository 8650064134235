<template>
  <div class="forget-4">
    <card class="card">
      <img src="~assets/img/auth/img_done.png" alt class="img-done" />
    </card>
    <div class="btns">
      <div class="btn" @click="actionLogin">
        <span>{{ $t.forgetInterpret.backLogin }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./components/Card";

export default {
  name: "ResetPasswordStep4",
  components: {
    Card,
  },
  data() {
    return {};
  },
  methods: {
    actionLogin() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "auth/login",
        name: "Login",
        query: [],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/var";
.forget-4 {
  padding: 2rem 1rem;
  & > h1 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: white;
    font-weight: bold;
  }

  .card {
    margin-top: 15px;
    .img-done {
      width: 284px;
      height: 42px;
      display: block;
      margin: 0 auto;
    }
  }

  .btns {
    margin: 43px 25px 60px;

    & > .btn {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $-color-background;
      border-radius: 25px;
      font-size: 15px;
      margin-bottom: 9px;
      border: 0.5px solid $-color-background;

      &.primary {
        background-color: transparent;
        color: white;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-submit {
    height: 50px;
    margin: 35px 25px 0;
    background-color: $-color-background;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 25px;
    font-size: 15px;
  }
  .bottom-action {
    font-size: 14px;
    color: $-color-background;
    text-align: center;
    position: absolute;
    bottom: 25px;
    width: 100%;
    margin: 0 -15px;
  }
}
</style>
